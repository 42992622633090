<template>
	<div class="account">
		<h2>Account Information</h2>
		<p>Please fill out the form below to make changes to your account or change your password.</p>

		<form class="form">
			<div class="form-group">
				<label>Email*</label>
				<input
					type="text"
					size="50"
					maxlength="50"
					class="text"
					style="cursor: auto"
					v-model="accountForm.email"
					required
				/>
			</div>
			<div class="form-group">
				<label>Current Password*</label>
				<input
					type="password"
					size="50"
					maxlength="50"
					class="text"
					style="cursor: auto"
					v-model="accountForm.currentPassword"
					required
				/>
			</div>
			<div class="form-group">
				<label>
					New Password
					<v-icon small color="#2e6e9e" style="cursor: help" :title="rules">
						mdi-information-outline
					</v-icon>
				</label>
				<input
					type="password"
					size="50"
					maxlength="50"
					class="text"
					style="cursor: auto"
					v-model="accountForm.newPassword"
					:title="rules"
				/>
			</div>
			<div class="form-group">
				<label>Re-Enter Password</label>
				<input
					type="password"
					size="50"
					maxlength="50"
					class="text"
					style="cursor: auto"
					v-model="accountForm.confirmNewPassword"
					:title="rules"
				/>
			</div>
			<div class="form-group">
				<label>Name*</label>
				<input
					type="text"
					size="50"
					maxlength="50"
					class="text"
					style="cursor: auto"
					v-model="accountForm.name"
					required
				/>
			</div>
			<div class="form-group">
				<label>Company*</label>
				<input
					type="text"
					size="50"
					maxlength="50"
					class="text"
					style="cursor: auto"
					v-model="accountForm.company"
					required
				/>
			</div>
			<div class="form-group">
				<label>Title*</label>
				<input
					type="text"
					size="50"
					maxlength="50"
					class="text"
					style="cursor: auto"
					v-model="accountForm.jobTitle"
					required
				/>
			</div>
			<div class="form-group">
				<label>Address 1*</label>
				<input
					type="text"
					size="50"
					maxlength="50"
					class="text"
					style="cursor: auto"
					v-model="accountForm.address1"
					required
				/>
			</div>
			<div class="form-group">
				<label>Address 2</label>
				<input
					type="text"
					size="50"
					maxlength="50"
					class="text"
					style="cursor: auto"
					v-model="accountForm.address2"
				/>
			</div>
			<div class="form-group">
				<label>Address 3</label>
				<input
					type="text"
					size="50"
					maxlength="50"
					class="text"
					style="cursor: auto"
					v-model="accountForm.address3"
				/>
			</div>
			<div class="form-group">
				<label>City*</label>
				<input
					type="text"
					size="50"
					maxlength="50"
					class="text"
					style="cursor: auto"
					v-model="accountForm.city"
					required
				/>
			</div>
			<div class="form-group">
				<label>State*</label>
				<input
					size="20"
					maxlength="50"
					class="text"
					style="cursor: auto"
					list="states-list"
					v-model="accountForm.state"
					required
				/>
				<datalist id="states-list">
					<option
						v-for="item in states"
						:title="item.state"
						:value="item.code"
						:key="item.code"
					></option>
				</datalist>
			</div>
			<div class="form-group">
				<label>Zip Code*</label>
				<input
					type="text"
					size="20"
					maxlength="20"
					class="text"
					style="cursor: auto"
					v-model="accountForm.zipCode"
					required
				/>
			</div>
			<div class="form-group">
				<label>Country</label>
				<input
					size="20"
					class="text"
					style="cursor: auto"
					list="country-list"
					v-model="accountForm.country"
				/>
				<datalist id="country-list">
					<option v-for="item in countries" :value="item.country" :key="item.code"></option>
				</datalist>
			</div>
			<div class="form-group">
				<label>Phone*</label>
				<input
					type="text"
					size="20"
					maxlength="20"
					class="text"
					style="cursor: auto"
					v-model="accountForm.phone"
					required
				/>
			</div>
			<div class="form-group">
				<label>Cell Phone</label>
				<input
					type="text"
					size="20"
					maxlength="20"
					class="text"
					style="cursor: auto"
					v-model="accountForm.cellPhone"
				/>
			</div>
			<div class="form-group mt-1">
				<label>&nbsp;</label>
				<input
					name="terms"
					type="checkbox"
					style="vertical-align: sub"
					v-model="accountForm.acceptedTerms"
					required
				/>
				<a
					style="font-size: 13px; margin-left: 10px"
					v-if="this.isMobile"
					:href="tncLink"
					:download="tncFile"
					target="_blank"
				>
					I accept the Terms and Conditions
				</a>
				<button
					v-else
					type="button"
					style="margin-left: 10px; font-size: 13px"
					class="form-btn-link"
					@click="showTerms = !showTerms"
				>
					I accept the Terms and Conditions
				</button>
			</div>
			<p style="float: left; clear: left; margin-top: 1rem">* Indicates that the field is required.</p>
			<button type="button" class="form-btn" style="float: right; margin-top: 1rem" @click="submit()">
				Enter
			</button>
		</form>

		<Modal v-if="showTerms" title="Terms and Conditions" @close="showTerms = false">
			<template v-slot:content>
				<iframe
					name="innerBrowserFrame"
					style="border: 0px; overflow-x: hidden; overflow-y: auto; height: 440px; width: 860px"
					:src="tncLink"
					scrolling="yes"
				></iframe>
			</template>
		</Modal>
		<Modal v-if="formError" title="Error" @close="formError = false">
			<template v-slot:content>
				<ul>
					<template v-for="(error, i) in errors">
						<li :key="i">{{ error }}</li>
					</template>
				</ul>
			</template>
		</Modal>
		<Modal v-if="showResponseModal" title="Response" @close="showResponseModal = false">
			<template v-slot:content>
				<ul>
					<template v-for="(message, i) in responseMessages">
						<li :key="i">{{ message }}</li>
					</template>
				</ul>
			</template>
		</Modal>
		<Modal
			v-if="success"
			title="Success"
			@close="
				() => {
					success = false;
					login();
				}
			"
		>
			<template v-slot:content>
				<span>Account Updated!</span>
			</template>
		</Modal>
	</div>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
	name: "Account",
	components: {
		Modal,
	},
	data() {
		return {
			accountForm: {
				userId: "",
				email: "",
				currentPassword: "",
				newPassword: "",
				confirmNewPassword: "",
				name: "",
				company: "",
				jobTitle: "",
				address1: "",
				address2: "",
				address3: "",
				city: "",
				state: "",
				zipCode: "",
				country: "",
				phone: "",
				cellPhone: "",
				acceptedTerms: false,
			},
			countries: [],
			states: [],
			errors: [],
			formError: false,
			showTerms: false,
			showResponseModal: false,
			responseMessages: [],
			loginDetails: {
				username: "",
				password: "",
			},
			success: false,
			rules: `Passwords must be at least 6 characters. \nPasswords must have at least one non alphanumeric character (i.e. !~@#$&*). \nPasswords must have at least one digit ('0'-'9'). \nPasswords must have at least one uppercase ('A'-'Z').`,
			isMobile: false,
			tncLink:
				"/api/storage/preview-file?filePath=%2FChemformation%2FBroChem%20Marketing%20Inc%20Price%20Book%2FMiscellaneous%20Products%20-%20use%20to%20start%2FChemformation%20Terms%20and%20Conditions%20Revised%206.7.23.pdf&dropboxId=id:JLJtptZ5JkUAAAAAAAFkwA",
			tncFile: "Chemformation Terms and Conditions",
		};
	},
	methods: {
		async submit() {
			let self = this;
			this.responseMessages = [];

			//validate form
			this.validateForm();

			try {
				if (!this.formError) {
					//send api post request
					const res = await this.$http.post(`/api/account/update`, this.accountForm);

					if (res) {
						let user = this.$store.getters.userInfo;
						this.loginDetails.username = user.username;
						this.loginDetails.password = this.accountForm.newPassword
							? this.accountForm.newPassword
							: this.accountForm.currentPassword;
						this.success = true;
					}
				}
			} catch (error) {
				if (error.response.data) {
					error.response.data.forEach(function (item) {
						self.responseMessages.push(item);
					});
					this.showResponseModal = true;
				}
			}
		},
		validateForm() {
			this.errors = [];

			if (!this.accountForm.email) {
				this.errors.push("Email is mandatory");
			}

			if (!this.accountForm.currentPassword) {
				this.errors.push("Current Password is mandatory");
			}

			if (this.accountForm.newPassword || this.accountForm.confirmNewPassword) {
				if (!this.accountForm.newPassword) {
					this.errors.push("New Password is mandatory");
				}

				if (!this.accountForm.confirmNewPassword) {
					this.errors.push("Re-Enter Password is mandatory");
				}

				if (this.accountForm.newPassword && this.accountForm.confirmNewPassword) {
					if (this.accountForm.newPassword !== this.accountForm.confirmNewPassword) {
						this.errors.push("New Password and Re-Enter Password do not match.");
					}
				}
			}

			if (!this.accountForm.name) {
				this.errors.push("Name is mandatory");
			}

			if (!this.accountForm.company) {
				this.errors.push("Company is mandatory");
			}

			if (!this.accountForm.jobTitle) {
				this.errors.push("Title is mandatory");
			}

			if (!this.accountForm.address1) {
				this.errors.push("Address 1 is mandatory");
			}

			if (!this.accountForm.city) {
				this.errors.push("City is mandatory");
			}

			if (!this.accountForm.state) {
				this.errors.push("State is mandatory");
			}

			if (!this.accountForm.zipCode) {
				this.errors.push("Zip Code is mandatory");
			}

			if (!this.accountForm.phone) {
				this.errors.push("Phone is mandatory");
			}

			if (!this.accountForm.acceptedTerms) {
				this.errors.push("Terms must be checked");
			}

			if (this.errors.length > 0) {
				this.formError = true;
			}
		},

		async listCountries() {
			const res = await this.$http.get(`/api/suggest/countries`);
			if (res && res.data) {
				this.countries = res.data;
			}
		},

		async listStates() {
			const res = await this.$http.get(`/api/suggest/states`);
			if (res && res.data) {
				this.states = res.data;
			}
		},

		async login() {
			this.$store.dispatch("login", this.loginDetails).then(() => {
				this.$router.push("/product-search");
			});
		},
	},
	mounted() {
		this.isMobile = this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.smOnly;
		let user = this.$store.getters.userInfo;
		if (user) {
			this.accountForm.userId = user.userId;
			this.accountForm.email = user.email;
			this.accountForm.name = `${user.firstName} ${user.lastName}`;
			this.accountForm.company = user.company;
			this.accountForm.jobTitle = user.title;
			this.accountForm.address1 = user.address1;
			this.accountForm.address2 = user.address2;
			this.accountForm.address3 = user.address3;
			this.accountForm.city = user.city;
			this.accountForm.state = user.state;
			this.accountForm.zipCode = user.zipCode;
			this.accountForm.phone = user.primaryPhone;
			this.accountForm.cellPhone = user.secondaryPhone;
			this.accountForm.acceptedTerms = user.acceptedTerms;
		}
		this.listCountries();
		this.listStates();
	},
};
</script>

<style lang="scss" scoped>
.account {
	h2 {
		margin: 1.5rem 0 1rem;
	}
}
</style>
